import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const NewsAndEvents = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 my-8">
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Featured Articles Section */}
        <div className="lg:w-2/3">
          <h2 className="text-3xl text-[#ff8400] mb-4">Featured articles</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <ArticleCard
              title="Co-ops: the new ESG investment vehicle for SMSF portfolios"
              author="Ashleigh Nichols"
              excerpt="Responsible Investing using Environmental, Social and Governance criteria is an opportunity to extend an investment beyond a purely financial transaction."
              image="/media/shutterstock_770002474-1200x560.jpg.webp"
              link="https://smsfconnect.com/article/co-ops-the-new-esg-investment-vehicle-for-smsf-portfolios"
            />
            <ArticleCard
              title="Green growth: Australian landscape for renewable energy investments"
              author="Jane Lawrence"
              excerpt="Global political action, extreme weather events, technological breakthroughs and changing consumer sentiments have shifted climate-related risks into an investment fundamental for all."
              image="/media/shutterstock_2051822582-1200x560.jpg.webp"
              link="https://smsfconnect.com/article/green-growth-australian-landscape-for-renewable-energy-investments"
            />
            <ArticleCard
              title="Socially Responsible Investment"
              author="Jane Lawrence"
              excerpt="Collectively, ethical investments with an emphasis on clean and green energy projects have the potential to fund Australia's transition to 100% renewable energy."
              image="/media/AdobeStock_227727671.webp"
              link="/socially-responsible-investment"
            />
            <ArticleCard
              title="Why Invest in Renewable Energy?"
              author="Jane Lawrence"
              excerpt="A look at why a community-scale renewable energy project should be at the top of your investment list."
              image="/media/pexels-cristian-rojas-8853511.jpg"
              link="/why-invest-in-renewable-energy"
            />
            <ArticleCard
              title="Why Join a Renewable Energy Cooperative?"
              author="Jane Lawrence"
              excerpt="How energy cooperatives are accelerating the movement toward clean energy."
              image="/media/AdobeStock_158281524.webp"
              link="/why-join-renewable-cooperative"
            />
          </div>
        </div>
        
        {/* Events Section */}
        <div className="lg:w-1/3">
          <h2 className="text-3xl text-[#ff8400] mb-4">Events</h2>
          <div className="space-y-4">
            <EventCard
              title="2024 OCREP Annual General Meeting"
              date="19th of November, 2024 at 7:30 pm (AEDT)"
              description="Members have been emailed the link to the Teams meeting."
              image="/media/pexels-pixabay-356036.webp"
            />
            <EventCard
              title="Taking Real Action: Benefits of Community Renewable Energy Ownership"
              date="Tuesday, 12 October 2021, 2 - 3 pm (AEDT)"
              description="Find out how individuals and businesses can take real action and benefit from community renewable energy ownership."
              image="/media/diesendorf.webp"
              presenter="Dr Mark Diesendorf"
            />
          </div>
        </div>
      </div>

      {/* Divider */}
      <div className="flex justify-center mt-16">
        <img
          src="/media/logo-icon.svg" 
          alt="Icon"
          className="w-10 h-10"
        />
      </div>
      
    </div>
  );
};

const ArticleCard = ({ title, author, excerpt, image, link }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden">
    <img src={image} alt={title} className="w-full h-48 object-cover" />
    <div className="p-4">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-md text-gray-600 mb-2">By {author}</p>
      <p className="text-md mb-4">{excerpt}</p>
      {link ? (
        link.startsWith('http') ? (
          <a href={link} target="_blank" rel="noopener noreferrer" className="text-[#703aa4] font-semibold flex items-center">
            Read article <ArrowRight className="ml-2 mt-0.5 h-4 w-4" />
          </a>
        ) : (
          <Link to={link} className="text-[#703aa4] font-semibold flex items-center">
            Read article <ArrowRight className="ml-2 mt-0.5 h-4 w-4" />
          </Link>
        )
      ) : null}
    </div>
  </div>
);

const EventCard = ({ title, date, description, image, presenter }) => (
  <div className="bg-[#703aa4] text-white rounded-lg shadow-md overflow-hidden">
    <img src={image} alt={title} className="w-full h-48 object-cover" />
    <div className="p-4">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-md mb-2">{date}</p>
      <p className="text-md mb-4">{description}</p>
      {presenter && <p className="text-sm mb-4">Presented by {presenter}</p>}
      {title.includes('AGM') ? (
        <button className="text-[#fed142] font-semibold">Join via Zoom</button>
      ) : (
        <button className="text-[#fed142] font-semibold flex items-center">
          Watch the recording <ArrowRight className="ml-2 mt-0.5 h-4 w-4" />
        </button>
      )}
    </div>
  </div>
);

export default NewsAndEvents;
