import React from 'react';
import { Menu, X } from 'lucide-react';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { Link } from 'react-router-dom'; 
import { FaXTwitter } from "react-icons/fa6";

const Layout = ({ children, isMenuOpen, toggleMenu }) => {
  return (
    <div className="min-h-screen flex flex-col bg-gray-200">
      {/* Header */}
      <header
        className={`bg-gradient-to-r from-[#703aa4] via-[#703aa4] to-[#ff8400] text-white shadow-md transition-all duration-300 ${
          isMenuOpen ? 'rounded-b-2xl' : ''
        }`}
      >
        <div className="max-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            <div className="flex items-center space-x-2">
              <a href="/">
                <img src="/media/main-logo.svg" alt="Energy Democracy Logo" className="h-12 w-auto" />
              </a>
            </div>
            <button 
              className="md:hidden focus:outline-none focus:ring-2 focus:ring-white rounded-md p-2"
              onClick={toggleMenu}
              aria-label="Toggle menu"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
            <nav className="hidden md:block">
              <ul className="flex space-x-6">
                <li><Link to="/" className="hover:text-[#FFD040] font-bold transition duration-300">Home</Link></li>
                <li><Link to="/about" className="hover:text-[#FFD040] font-bold transition duration-300">About</Link></li>
                <li><Link to="/news" className="hover:text-[#FFD040] font-bold transition duration-300">News & Events</Link></li>
                <li><Link to="/central-west" className="hover:text-[#FFD040] font-bold transition duration-300">Central West</Link></li>
                <li><Link to="/faq" className="hover:text-[#FFD040] font-bold transition duration-300">FAQ</Link></li>
                <li><Link to="/contact" className="hover:text-[#FFD040] font-bold transition duration-300">Contact</Link></li>
              </ul>
            </nav>
          </div>
        </div>
        <nav className={`${isMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'} md:hidden bg-gray-400 bg-opacity-20 backdrop-blur-2xl px-4 py-2 transition-all duration-500 ease-in-out overflow-hidden rounded-2xl`}>
          <ul className="space-y-2">
            <li><Link to="/" className="block hover:text-gray-400 px-2 py-1 rounded transition duration-300">Home</Link></li>
            <li><Link to="/about" className="block hover:text-gray-400 px-2 py-1 rounded transition duration-300">About</Link></li>
            <li><Link to="/news" className="block hover:text-gray-400 px-2 py-1 rounded transition duration-300">News & Events</Link></li>
            <li><Link to="/central-west" className="block hover:text-gray-400 px-2 py-1 rounded transition duration-300">Central West</Link></li>
            <li><Link to="/faq" className="block hover:text-gray-400 px-2 py-1 rounded transition duration-300">FAQ</Link></li>
            <li><Link to="/contact" className="block hover:text-gray-400 px-2 py-1 rounded transition duration-300">Contact</Link></li>
          </ul>
        </nav>
      </header>

      {/* Main content */}
      <main className="flex-grow">
        <div className="max-full mx-auto px-4 sm:px-6 lg:px-8">
          {children}
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-[#53565a] text-white py-6">
        <div className="max-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-start">
            <div className="flex flex-col items-start">
              <img
                src="/media/main-logo.svg"
                alt="Company Logo"
                className="h-8 sm:h-10 mb-2 sm:mb-4"
              />
              <div className="flex space-x-2 sm:space-x-4">
                <a href="https://www.facebook.com/EnergyDemocracyCoOperatives/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#ff8400]">
                  <FaFacebookF size={18} className="sm:w-5 sm:h-5" />
                </a>
                <a href="https://www.instagram.com/ed_co_op/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#ff8400]">
                  <FaInstagram size={18} className="sm:w-5 sm:h-5" />
                </a>
                <a href="https://www.linkedin.com/company/energy-democracy/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#ff8400]">
                  <FaLinkedinIn size={18} className="sm:w-5 sm:h-5" />
                </a>
                <a href="https://x.com/ed_co_op" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#ff8400]">
                  <FaXTwitter size={18} className="sm:w-5 sm:h-5" />
                </a>
              </div>
            </div>

            <div className="text-right text-xs sm:text-sm">
              <p>
                <a href="mailto:info@energydemocracy.net" className="underline hover:text-[#ff8400] transition duration-300">
                  info@energydemocracy.net
                </a>
              </p>
              <p>Suite 1, Level 8</p>
              <p>117 York Street</p>
              <p>Sydney, NSW 2000</p>
            </div>
          </div>

          <hr className="border-gray-400 my-4 sm:my-6" />

          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
            <p className="text-xs sm:text-sm text-left mb-2 sm:mb-0">
              Energy Democracy Central West New South Wales Co-operative
            </p>
            <p className="text-xs sm:text-sm text-left sm:text-right">
              © 2024 Energy Democracy. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;


