import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        className="w-full text-left py-4 flex items-start justify-between focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-xl text-[#703aa4] pr-4 flex-grow">{question}</span>
        <span className="flex-shrink-0 mt-1 ml-2 min-w-[20px]">
          {isOpen ? <ChevronUp className="h-5 w-5 text-[#703aa4]" /> : <ChevronDown className="h-5 w-5 text-[#703aa4]" />}
        </span>
      </button>
      <div 
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <div className="pb-4 text-xl text-gray-600">
          <p>{answer}</p>
        </div>
      </div>
    </div>
  );
};

const FAQSection = ({ title, faqs }) => (
  <section className="mb-12">
    <h2 className="text-2xl font-medium text-[#703aa4] mb-6 pb-2 border-b-2 border-[#703aa4] inline-block">{title}</h2>
    <div className="space-y-1">
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  </section>
);

const LogoDivider = () => (
  <div className="flex justify-center my-12">
    <img src="/media/logo-icon.svg" alt="Logo Icon" className="w-8 h-8" />
  </div>
);

const FAQ = () => {
  const faqSections = [
    {
      title: "Central West NSW Co-operative",
      faqs: [
        {
          question: "What if the Co-operative can't raise all the money? Will the renewable energy park still be built?",
          answer: "The Park will be built regardless of how much the Co-operative is able to raise in the time available. The project has been underwritten, meaning the underwriter will cover any shortfall in funds raised and increase its stake accordingly."
        },
        {
          question: "How will the park make money?",
          answer: "The electricity generated in the park is fed into the National Grid. Excess generation is also used to recharge the battery for later usage, or for solar energy to be sold when electricity prices are highest."
        },
        {
          question: "What is my return on investment?",
          answer: "The average payback is estimated at 8.6 years for one parcel, providing a return on your investment of 8 – 12% *."
        },
        {
          question: "Can I buy more than one parcel?",
          answer: "Yes, any member be they a household, small business or an investor can purchase more than one parcel. Multiple parcels of shares can be purchased for your SMSF, or for friends and family as an investment. There is one caveat – under the Co-operative National Law, no-one can own more than 20% of the Co-operative without the permission of the regulator and Co-op board."
        },
        {
          question: "What is a disclosure statement and why do I need to read it?",
          answer: "The disclosure statement is a regulatory requirement since the Co-operative is raising money from members of the public. It sets out information one should know before making an investment. The Energy Democracy Central West NSW Co-operative has registered a disclosure statement with Fair Trading NSW that details the offer, risks, financials, and assumptions. It is important you read and understand the offer, or seek financial advice before investing in the Co-op."
        },
      ]
    },
    {
      title: "Co-operatives",
      faqs: [
        {
          question: "What is a Co-operative?",
          answer: "A Co-operative is a self-governing association of people, who collectively and voluntarily work together to achieve their agreed common economic, social and cultural needs and aspirations through a jointly owned and democratically controlled enterprise. Co-operatives everywhere operate according to a set of core principles and values that fundamentally put the needs of their members first. The principles are:"
        },
        {
          question: "Who is an Energy Democracy Co-operative for?",
          answer: "An Energy Democracy Co-operative presents individuals and small businesses a new opportunity to benefit from renewable energy asset investment. As a distributing cooperative, Energy Democracy Central West NSW Co-operative has been registered with Fair Trading NSW."
        },
      ]
    },
    {
      title: "Shares",
      faqs: [
        {
          question: "Who can own shares in a Co-op?",
          answer: "Anyone within Australia can invest and own shares in the Co-op *."
        },
        {
          question: "How do I purchase shares?",
          answer: "To apply to Membership of the Central West NSW Co-op and purchase shares, please visit the Contact page to enquire for more information."
        },
        {
          question: "What is the minimum share subscription?",
          answer: "One share parcel, equivalent to 1000 shares."
        },
        {
          question: "Can I own more shares than the minimum share subscription?",
          answer: "Yes, you can buy more shares than the minimum share subscription."
        },
        {
          question: "Is there a cap on how many shares I can own?",
          answer: "Yes. The National Co-operative Law stipulates that the maximum number of shares that one member can own is limited to 20% of the share capital without prior approval from the Co-operative Board and the Regulator."
        },
        {
          question: "How can I sell my shares?",
          answer: "Members may sell their shares in the community solar park. To sell a share you must find a willing buyer first. Energy Democracy will facilitate easy transfer of shares by liaising with people who are looking at buying or selling existing shares and keeping a register of interested buyers and sellers."
        },
      ]
    },
    {
      title: "Members",
      faqs: [
        {
          question: "How many votes do I get for each parcel of shares I own?",
          answer: "In the spirit of co-operatives and under the Co-operative National Law, each member has one vote regardless of the number of shares they own."
        },
        {
          question: "Is membership limited to local residents?",
          answer: "No, anyone within Australia can join the Co-operative *."
        },
        {
          question: "What are the membership requirements?",
          answer: "The Energy Democracy Co-operatives require a minimum level of activity from members. To retain membership, you will need to continue to fulfil active participation requirements which are detailed in the Disclosure Statement for each Co-operative."
        },
        {
          question: "What is the legal liability of Energy Democracy Co-operative members?",
          answer: "The legal liability is limited to the value of shares held by a member."
        },
        {
          question: "Can I apply for a membership as a joint owner?",
          answer: "The constitution permits joint holders, including partners, organisations and self-managed super funds (SMSF)."
        },
        {
          question: "Is someone available to talk to us from Energy Democracy?",
          answer: "If you would like to discuss a potential energy Co-operative, or would like someone to speak at an event about sustainable energy, or you would like to visit a solar park, please contact us and let us know your requirements at info@energydemocracy.net."
        },
      ]
    },
    {
      title: "Electricity Sale and Income",
      faqs: [
        {
          question: "Will the expected return fluctuate per the level of power the solar park generates?",
          answer: "While summer months produce more energy than winter months, the estimated return in the Disclosure Statement is calculated as an average across the entire project life."
        },
        {
          question: "Will the project store power using batteries?",
          answer: "Yes, batteries are included in the solar parks."
        },
        {
          question: "How will the co-operatives offset carbon emissions?",
          answer: "Traditional fossil fuel generators (such as coal and gas) contribute significantly to Australia's carbon emissions in the electricity sector. Solar PV does not produce carbon emissions during operation, and therefore is a clean form of energy generation. Solar PV projects are eligible to create Renewable Energy Certificates (RECs) for the renewable energy that is generated, throughout the life of the scheme. The Co-operative will sell these RECs, where its value is determined by the demand to offset emissions in a carbon-intensive grid with renewable energy generation."
        },
      ]
    },
    {
      title: "Management and Governance",
      faqs: [
        {
          question: "How can I get involved with the board of directors?",
          answer: "You are encouraged to actively participate in your local Co-operative, so do consider a role on your Co-operative's board. Please note your interest in your Registration of Interest."
        },
        {
          question: "Where can I find financial projections?",
          answer: "Financial projections can be found in the Disclosure Statement."
        },
        {
          question: "What guarantees and insurance are there?",
          answer: "Each Energy Democracy Co-operative carries insurance to help manage its risks. Please read the Disclosure Statement for more on equipment guarantees."
        },
        {
          question: "What happens if weather, vandals or some unforseen cicumstance damages the solar park?",
          answer: "The solar park will be covered by insurance. The solar panel and inverter equipment manufacturers provide additional guarantees."
        },
      ]
    },
    {
      title: "Environmental",
      faqs: [
        {
          question: "What is the energy payback period?",
          answer: "Energy payback is the time that it takes a generator to produce as much energy as was used in its construction. This includes the energy in the manufacturing processes and site construction activities."
        },
        {
          question: "How much water is used for cleaning the panels?",
          answer: "Panels accumulate small amounts of dust which degrades generation. The maintenance of the solar park includes regular cleaning to maximise return on asset. Very little water will be required during cleaning."
        },
        {
          question: "Are there problems with glare effects from the solar panels?",
          answer: "Two reports, 'The Mount Majura solar park – Glare Analysis', prepared by CBRE and 'Solar Glare Aviation Hazard Evaluation Engineering Report' prepared by Canadian Solar conclude that whilst some level of glare is to be expected from the solar arrays, the impact of this glare to surrounding land users, vehicles or aircraft is 'low potential' and not likely to be hazardous."
        },
      ]
    },
    {
      title: "Technology",
      faqs: [
        {
          question: "Have other renewable energy generation technologies been considered?",
          answer: "The Energy Democracy Co-operatives are technology agnostic; we are open to the use of different technology tools as they are developed and prove their worth. Our focus is on commercially proven clean energy generation that presents a lower risk for community investment."
        },
      ]
    },
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 my-8">
      <h1 className="text-3xl text-[#ff8400] mb-12">Frequently asked questions</h1>
      {faqSections.map((section, index) => (
        <React.Fragment key={index}>
          <FAQSection title={section.title} faqs={section.faqs} />
          {index < faqSections.length - 1 && <LogoDivider />}
        </React.Fragment>
      ))}

      {/* Final Logo */}
      <div className="flex justify-center mt-12">
        <img src="/media/logo-icon.svg" alt="Energy Democracy Logo" className="w-8 h-8" />
      </div>

      {/* Disclaimer Section */}
      <div className="mt-12 pt-8 border-t border-gray-200">
        <hr className="border-t border-gray-600 w-full mb-4" />
          <p className="text-sm text-gray-600">
          * Please read the <a href="/media/Energy%20Democracy%20Disclosure%20Statement-2e79b172bc661b833ded6066d87fd44f.pdf" target="_blank" className="text-[#703aa4] underline">Disclosure Statement</a>. No application for relief under section 741 of the Corporations Act 2001 (Cth) will be sought by the Energy Democracy Central West NSW Co-operative Limited ('the Co-operative') in relation to any statement made in this document as the Chapter 6D disclosure provisions do not apply to members' shares in the Co-operative.
          </p>
      </div>
    </div>
  );
};

export default FAQ;