import React, { useEffect } from 'react';

const SociallyResponsibleInvestment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 my-8">
      <h1 className="text-3xl text-[#703aa4] mb-6">Socially Responsible Investment</h1>
      
      <div className="mb-4">
        <p className="text-sm text-gray-600">By Jane Lawrence</p>
        <p className="text-sm text-gray-600">11 Oct 2021</p>
      </div>

      <img 
        src="/media/AdobeStock_227727671.webp" 
        alt="Socially Responsible Investment" 
        className="w-full h-64 object-cover rounded-lg shadow-md mb-8"
      />

      <div className="space-y-6 text-gray-700 leading-relaxed">
        <p>Moving your super investments out of fossil fuels and into renewable energy makes sense. Not only are fossil fuel generation plants about to pass their use-by date, but any investment in these is also inappropriate given the climate crisis we're facing.</p>

        <p>We're all looking for socially responsible investments. I know I am!</p>

        <p>For this to happen we need to become more informed with respect to our investment decisions and seek out more ethical investment opportunities.</p>

        <p>Investments in companies that support fossil fuels and are time-limited, and questionable quality investments over the long term.</p>

        <p>Collectively, ethical investments with an emphasis on clean and green energy projects have the potential to fund Australia's transition to 100% renewable energy.</p>

        <p>An investment in a community-owned renewable energy park, such as the Orange Community Renewable Energy Park (OCREP), is one way to make a small but significant step toward achieving both a worthwhile financial return and social and environmental gains.</p>

        <p>Community investment projects are typically initiated and led by a community organisation such as a co-operative or company. This particular renewable energy project is set up for investment from individuals and SMSF from throughout Australia by way of membership in Energy Democracy Central West NSW Co-operative.</p>

        <p>Co-operatives have a defined structure and Rules that can only be changed by its members. These and a Disclosure Statement, which must be submitted to Fair Trading NSW for approval, provide the framework for the Co-operative to operate within. A Co-operative is a particularly democratic organisation. Each member has one vote, never mind how many shares they have purchased. Each member has the opportunity to represent the other members on the Board of Directors.</p>

        <p>As a member of the Energy Democracy Co-operative, you choose how many share parcels you would like to purchase in line with your personal investment strategy. As this Co-operative is a distributing co-operative, dividends are distributed commensurate to the amount of shares you have purchased. In this case, the expected return on your investment is between 8-12%. The membership can choose to distribute some of the Co-operative's income to the broader community.</p>

        <p>Community investment and ownership in clean, green initiatives by way of a co-operative has the potential to bring about positive social change and provide support that is much needed in these uncertain times.</p>

        <p>If you are looking for ways to contribute to the transition to a low carbon future, choose to invest in projects that make such a vision for your future, a reality.</p>
      </div>

      {/* Divider */}
      <div className="flex justify-center mt-16">
        <img
          src="/media/logo-icon.svg" 
          alt="Icon"
          className="w-10 h-10"
        />
      </div>
    </div>
  );
};

export default SociallyResponsibleInvestment;