import React, { useEffect } from 'react';

const WhyInvestInRenewableEnergy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 my-8">
      <h1 className="text-3xl text-[#703aa4] mb-6">Why Invest in Renewable Energy?</h1>
      
      <div className="mb-4">
        <p className="text-sm text-gray-600">By Jane Lawrence</p>
        <p className="text-sm text-gray-600">21 Sep 2021</p>
      </div>

      <img 
        src="/media/pexels-cristian-rojas-8853511.jpg" 
        alt="Renewable Energy Investment" 
        className="w-full h-64 object-cover rounded-lg shadow-md mb-8"
      />

      <div className="space-y-6 text-gray-700 leading-relaxed">
        <p>The transition in investment from fossil fuels to renewable energy is well underway.</p>
        
        <p>Renewable energy investment has increased significantly in Australia over recent years, with high electricity prices, reducing costs of renewable generation technology, government policy incentives, and lower costs of finance contributing to this shift in investment strategy.</p>

        <p>Investment in renewable energy generation has been supported by a significant increase in wholesale prices in the National Electricity Market (NEM). With coal-fired plants due to retire and capacity removed from the system, the expectation is for higher wholesale electricity prices. This would encourage further investment in renewable generation.</p>

        <p>Returns in the US, UK, and Europe, suggest renewable energy investments are delivering massively better returns than fossil fuels. Some superannuation funds are choosing to move away from investing in fossil fuels and the Big Four banks have also announced that they are reducing their financing of coal.</p>

        <p>Households have been the main driver of small-scale renewable investment in Australia. Advancements in renewable technology has diversified the grid, adding new sources of energy generation and two-way power flows, enabling people to install rooftop solar photovoltaic (PV) panels to generate their own electricity, and even get paid to supply the grid via a feed-in tariff.</p>

        <p>However, the rapidly increasing supply of renewable energy generation capacity on residential properties in remote locations and the retirement of coal-fired generation, presents challenges for the Australian Energy Market Operator (AEMO) to maintain grid security. In some areas, feed-in has been restricted because of insufficient grid transmission capacity, reducing the revenue earned for electricity produced.</p>

        <p>Few of these households have a battery to store the excess energy produced during the day to feed-in to the grid after sundown, which is often when demand peaks.</p>

        <p>There are many who would like to invest in this transition to renewables and a low carbon future, but they don't own or have a suitable roof to install solar PV.</p>

        <p>To provide as many people as possible with an opportunity to participate in the transition to a low carbon future, Energy Democracy Central West NSW Cooperative was established to take an ownership stake in a community-owned renewable energy park on the outskirts of Orange.</p>

        <p>If you are looking for an ethical, green investment then this community-scale renewable energy project should be at the top of your investment list!</p>

        <p>As a member of the Energy Democracy cooperative, you can purchase share parcels equivalent to 0.5kW of solar PV panels and 0.5kWh of battery storage. The battery is the game-changer. The battery energy storage system (BESS) not only helps support grid stability but allows power to be spot sold into the wholesale market at best price. The dividends are paid to the members of the cooperative.</p>

        <p>Your window of opportunity for this socially responsible, direct investment in the Orange Community Renewable Energy Park is now. Financial close is at the end of October when procurement begins for construction in the first quarter of 2022.</p>

        <p>The next step is to apply for membership in the Energy Democracy Central West NSW Cooperative so that you can purchase share parcels, diversify your investment portfolio and participate in the transition to a low carbon future. Our Planet Earth needs our collective action!</p>
      </div>

      {/* Divider */}
      <div className="flex justify-center mt-16">
        <img
          src="/media/logo-icon.svg" 
          alt="Icon"
          className="w-10 h-10"
        />
      </div>
    </div>
  );
};

export default WhyInvestInRenewableEnergy;