import React, { useEffect } from 'react';

const WhyJoinRenewableCooperative = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 my-8">
      <h1 className="text-3xl text-[#703aa4] mb-6">Why Join a Community Renewable Energy Cooperative?</h1>
      
      <div className="mb-4">
        <p className="text-sm text-gray-600">By Jane Lawrence</p>
        <p className="text-sm text-gray-600">24 Sep 2021</p>
      </div>

      <img 
        src="/media/AdobeStock_158281524.webp" 
        alt="Community Renewable Energy Cooperative" 
        className="w-full h-64 object-cover rounded-lg shadow-md mb-8"
      />

      <div className="space-y-6 text-gray-700 leading-relaxed">
        <p>As the movement toward cleaner energy gains momentum, a growing number of community-scale renewable energy facilities are being constructed across Australia.</p>

        <p>Arrays of solar PV panels, with mid-sized energy generation capacity of 5MW or less and connected to the distribution grid, have the best potential for community-scale renewable energy projects. The inclusion of a battery allows the flow of electricity into the larger grid at peak times outside of daylight hours that won’t contribute to grid instability.</p>

        <p>Not only do community-scale solar parks capture the economies of scale of larger solar farms, but these renewable energy facilities have advantages over large-scale centralised projects that result in inefficiencies in transmission. Moreover, NSW is providing subsidies to support community-scale projects via their Regional Community Energy Fund. On the other hand, individual distributed generation, such as solar infrastructure on the roofs of homes, involves high transaction costs and creates relatively small impacts.</p>

        <p>While both individual-scale and large-scale generation facilities have important roles in the development and access of clean energy nationwide, local ownership of community-scale renewable energy projects offers many benefits. To be “community-scale” energy, the generation project must be initiated by a community of residents to business owners who are involved in some of the stages of planning the project. Communities must be able to form business enterprises that own, finance, govern the purchase, installation, operation, and maintenance of generation infrastructure and manage the sale of energy produced.</p>

        <p>When built and utilised at the community level and supported by community resources pooled from private parties in close geographic proximity, these solar parks lend themselves to a cooperative model of collective ownership. Set up for the benefit of its members, an energy cooperative is a private, voluntary, and democratic alternative to a private corporate business enterprise or a public company. Although it has its own legal identity separate from its members, a co-operative differs from a public company because voting rights are based on membership (i.e. one member, one vote) rather than shareholding.</p>

        <p>The formation of an energy cooperative where members of the cooperative collectively finance an ownership stake in the local renewable energy park, gives communities more independence in generating electricity and builds energy resilient communities. Shareholder-owners in a co-operatively owned renewable energy facility reap the benefits of economies of scale and more favourable returns on their investment than when working individually.</p>

        <p>As well as a share in the energy produced, the values of mutual support and ongoing conversations that promote understanding for those adopting new energy technologies are further key benefits as a member in an energy cooperative. Not only is the concept of collective ownership relatively new for community-scale distributed generation but membership in an energy cooperative could be seen as a bold leap even for the most innovative of investors.</p>

        <p>Used to achieve common goals, cooperatives have existed for many years and have most commonly been used for marketing and procurement services for farmers’ cooperatives and member-owned food stores and local buying clubs. Worker cooperatives have also been formed by the workers and for the workers, to provide professional, financing and insurance services to its members. Distributing cooperatives are types of business structures that allow their members to maximise benefits with profits distributed among members in proportion to their use of the services provided by the cooperative. The challenge then is for these communities of volunteers to sustain the momentum required for the duration of the project.</p>

        <p>Energy Democracy works with the community to bring all the necessary elements of a community-owned renewable energy enterprise together: establishing an energy cooperative to take an ownership stake in the renewable energy project, identifying and acquiring suitable land, gaining planning approvals, procuring, purchasing and installing generation infrastructure and energy technology, ongoing maintenance and operation, and managing the sale of energy produced. Energy Democracy then provides ongoing management services to manage the generation and sale of energy from the panels and batteries into the grid at best price. The dividends from the sale of electricity, minus costs, are distributed to members of the energy cooperative commensurate with the number of share parcels they own.</p>

        <p>
            As a distributing cooperative, the{' '}
            <a 
                href="/media/Energy%20Democracy%20Disclosure%20Statement-2e79b172bc661b833ded6066d87fd44f.pdf" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-[#703aa4] underline"
            >
                Disclosure Statement
            </a>{' '}
            for the Energy Democracy Central West NSW Co-operative has been registered with Fair Trading NSW.
        </p>

        <p>We highly recommend you invest in the transition to a low carbon future. Collectively we can make a difference.</p>
      </div>

      {/* Divider */}
      <div className="flex justify-center mt-16">
        <img
          src="/media/logo-icon.svg" 
          alt="Icon"
          className="w-10 h-10"
        />
      </div>
    </div>
  );
};

export default WhyJoinRenewableCooperative;