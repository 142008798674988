import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Layout from './pages/Layout'; 
import MainPage from './pages/MainPage'; 
import About from './pages/About'; 
import News from './pages/News';
import CentralWest from './pages/CentralWest';
import FAQ from './pages/FAQ';
import Contact from './pages/Contact';
import SociallyResponsibleInvestment from './pages/SociallyResponsibleInvestment';
import WhyInvestInRenewableEnergy from './pages/WhyInvestInRenewableEnergy';
import WhyJoinRenewableCooperative from './pages/WhyJoinRenewableCooperative';

const AppContent = ({ isMenuOpen, setIsMenuOpen, toggleMenu }) => {
  const location = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location, setIsMenuOpen]);

  return (
    <Layout isMenuOpen={isMenuOpen} toggleMenu={toggleMenu}>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/news" element={<News />} />
        <Route path="/central-west" element={<CentralWest />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/socially-responsible-investment" element={<SociallyResponsibleInvestment />} />
        <Route path="/why-invest-in-renewable-energy" element={<WhyInvestInRenewableEnergy />} />
        <Route path="/why-join-renewable-cooperative" element={<WhyJoinRenewableCooperative />} />
      </Routes>
    </Layout>
  );
};

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <Router>
      <AppContent isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} toggleMenu={toggleMenu} />
    </Router>
  );
};

export default App;
