import React from 'react';

const About = () => {
  return (
    <div className="my-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Who We Are section */}
      <section className="bg-[#703aa4] rounded-xl p-6 mt-8 shadow-md">
        <div className="flex flex-col md:flex-row items-center">
          <div className="text-[#fed142] md:w-1/2 flex flex-col justify-center text-center md:text-right md:pr-6">
            <h2 className="text-4xl mb-4">Who We Are</h2>
            <p className="text-xl text-gray-200">
              Energy Democracy exists to democratise the transition to a low carbon economy, helping individuals to save power, save money, and save the planet. We establish locally owned renewable energy co-operatives and build, operate and maintain renewable energy parks for the co-ops.
            </p>
          </div>
          <div className="md:w-1/2 mt-6 md:mt-0 md:pl-6">
            <video
              src="/media/intro-small.mp4"
              controls
              className="rounded-lg w-full max-w-md mx-auto"
            />
          </div>
        </div>
      </section>

      {/* Enabling communities section */}
      <section className="my-16">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-2/3 flex flex-col justify-center text-center md:text-left md:pr-6">
            <h2 className="text-3xl text-[#ff8400] mb-4">Enabling communities to combat climate change</h2>
            <p className="text-xl text-gray-600">
              The world is facing a climate emergency. You can make a difference through a climate positive investment.
              <br /><br />
              Energy Democracy's community energy model engages the wider community through renewable energy initiatives to achieve tangible benefits for the climate. We build more resilient communities in this climate-impacted world and empower people to understand how they are using energy to provide social, economic and environmental benefits.
            </p>
          </div>
          <div className="md:w-1/3 mt-6 md:mt-0 flex justify-center md:justify-end">
            <img
              src="/media/pexels-pixabay-356036.webp" 
              alt="Community Engagement"
              className="rounded-lg w-full md:max-w-xs shadow-md"
            />
          </div>
        </div>
      </section>

      {/* Divider */}
      <div className="flex justify-center my-16">
        <img
          src="/media/logo-icon.svg" 
          alt="Icon"
          className="w-10 h-10"
        />
      </div>

      {/* Vision Section */}
      <section className="my-16">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-2/5 flex justify-center md:justify-start mb-6 md:mb-0 md:pr-6">
            <img
              src="/media/Alan.webp"
              alt="Alan Major"
              className="rounded-lg w-full max-w-xs shadow-md"
            />
          </div>
          <div className="md:w-3/5 text-center md:text-left">
            <h2 className="text-3xl text-[#ff8400] mb-4">Vision</h2>
            <p className="text-xl text-gray-600">
              Energy Democracy is the vision of Alan Major. After restructuring the Northern Territory YHA in 2008 to ensure the movement's sustainability, Alan was challenged to not just get another job, but to do something that would change the world.
              <br /><br />
              Alan chose to commit his efforts to improving the uptake of renewable energy to help reduce the impact of climate change. He observed the rapid uptake of rooftop solar PV in Australia but noticed there are a lot of people missing out, simply because they don't have a suitable roof.
            </p>
          </div>
        </div>
      </section>

      {/* Divider */}
      <div className="flex justify-center mt-16">
        <img
          src="/media/logo-icon.svg" 
          alt="Icon"
          className="w-10 h-10"
        />
      </div>
    </div>
  );
};

export default About;







