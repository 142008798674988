import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

const Contact = () => {
  const getformEndpoint = 'https://getform.io/f/d8dded64-137e-47cb-91b1-8dc4b18f6f30'; 

  const australianStates = [
    "Australian Capital Territory",
    "New South Wales",
    "Northern Territory",
    "Queensland",
    "South Australia",
    "Tasmania",
    "Victoria",
    "Western Australia"
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 my-8">
      <h1 className="text-3xl text-[#ff8400] mb-2">We would love to connect!</h1>
      <h2 className="text-2xl text-[#703aa4] mb-6">Drop us a line</h2>

      <div className="flex flex-col md:flex-row gap-8">
        <form 
          action={getformEndpoint} 
          method="POST" 
          className="w-full md:w-2/3 space-y-6"
        >
          <div className="flex gap-4">
            <div className="w-1/2">
              <label htmlFor="firstName" className="block text-base font-medium text-gray-700 mb-2">First name</label>
              <input type="text" id="firstName" name="first-name" required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#703aa4] focus:ring focus:ring-[#703aa4] focus:ring-opacity-50 text-lg py-2 px-3"
              />
            </div>
            <div className="w-1/2">
              <label htmlFor="lastName" className="block text-base font-medium text-gray-700 mb-2">Last name</label>
              <input type="text" id="lastName" name="last-name" required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#703aa4] focus:ring focus:ring-[#703aa4] focus:ring-opacity-50 text-lg py-2 px-3"
              />
            </div>
          </div>

          <div className="flex gap-4">
            <div className="w-1/2">
              <label htmlFor="email" className="block text-base font-medium text-gray-700 mb-2">Email</label>
              <input type="email" id="email" name="email" required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#703aa4] focus:ring focus:ring-[#703aa4] focus:ring-opacity-50 text-lg py-2 px-3"
              />
            </div>
            <div className="w-1/2">
              <label htmlFor="phone" className="block text-base font-medium text-gray-700 mb-2">Phone</label>
              <input type="tel" id="phone" name="phone"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#703aa4] focus:ring focus:ring-[#703aa4] focus:ring-opacity-50 text-lg py-2 px-3"
              />
            </div>
          </div>

          <div className="flex gap-4">
            <div className="w-1/2">
              <label htmlFor="state" className="block text-base font-medium text-gray-700 mb-2">State</label>
              <select id="state" name="state"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#703aa4] focus:ring focus:ring-[#703aa4] focus:ring-opacity-50 text-lg py-2 px-3"
              >
                {australianStates.map((state) => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </select>
            </div>
            <div className="w-1/2">
              <label htmlFor="postcode" className="block text-base font-medium text-gray-700 mb-2">Postal code</label>
              <input type="text" id="postcode" name="postcode"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#703aa4] focus:ring focus:ring-[#703aa4] focus:ring-opacity-50 text-lg py-2 px-3"
              />
            </div>
          </div>

          <div>
            <label htmlFor="message" className="block text-base font-medium text-gray-700 mb-2">Message</label>
            <textarea id="message" name="message" rows="4"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#703aa4] focus:ring focus:ring-[#703aa4] focus:ring-opacity-50 text-lg py-2 px-3"
            ></textarea>
          </div>

          {/* Disclaimer Section */}
          <div className="text-sm text-gray-600">
            <p>
              * By completing this form you confirm that you have read and agree to the terms of the <a href="/media/Data Protection Policy-08a334ec859e764554c51a30074c3eca.pdf" target="_blank" className="text-[#703aa4] underline">Data Privacy Policy</a>.
            </p>
          </div>

          <div>
            <button type="submit"
              className="bg-[#703aa4] text-white px-6 py-2 rounded-md hover:bg-opacity-90 transition duration-300"
            >
              Submit
            </button>
          </div>
        </form>

        <div className="w-full md:w-1/3 md:self-start">
          <div className="bg-[#703aa4] text-white p-6 rounded-lg flex flex-col justify-between h-auto">
            <div>
              <h3 className="text-xl font-semibold mb-4">Australia</h3>
              <p className="mb-4">1300 144 990</p>
            </div>
            <div className="border-t border-white opacity-30 my-2"></div>
            <div className="flex space-x-4 mt-4">
              <a href="https://www.facebook.com/EnergyDemocracyCoOperatives/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#ff8400]"><FaFacebookF /></a>
              <a href="https://www.instagram.com/ed_co_op/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#ff8400]"><FaInstagram /></a>
              <a href="https://www.linkedin.com/company/energy-democracy/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#ff8400]"><FaLinkedinIn /></a>
              <a href="https://x.com/ed_co_op" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#ff8400]"><FaXTwitter /></a>
            </div>
          </div>
        </div>
      </div>

      {/* Divider */}
      <div className="flex justify-center mt-16">
        <img
          src="/media/logo-icon.svg" 
          alt="Icon"
          className="w-10 h-10"
        />
      </div>
    </div>
  );
};

export default Contact;