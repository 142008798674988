import React from 'react';
import { FaArrowTrendUp, FaLeaf } from 'react-icons/fa6';
import { TbSunElectricity } from 'react-icons/tb';
import { MdEmojiPeople } from 'react-icons/md';

const CentralWest = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 my-8">
      <h1 className="text-3xl text-[#ff8400] mb-6">Central West NSW Co-operative</h1>
      
      {/* Introduction Section */}
      <section className="mb-8">
        <p className="text-black text-xl mb-4">
        The Orange Community Renewable Energy Park (OCREP) is a 5 MW solar installation featuring 10 MWh battery storage technology and solar tracking mechanisms. The OCREP is the largest crowd funded PV project with solar batteries in Australia and is being developed at 643 Mitchell Highway, Orange NSW. Construction is planned to commence in November 2024.
        </p>
        <div className="flex justify-center mt-8"></div>
        <img 
          src="/media/Arid-landscape.webp" 
          alt="Central West Landscape" 
          className="w-full h-96 object-cover rounded-lg shadow-md mb-4"
        />
      </section>

      {/* Renewable Energy Projects Section */}
      <section className="mb-12">
        <p className="text-black text-xl mb-4">
        The average payback is estimated at up to 8.6 years, providing a return on investment of 8 – 12% per annum. 
        </p>
        <p className="text-black text-xl mb-4">
          While applications for new members in the Energy Democracy Central West NSW Co-Operative are closed and new shares can no longer be issued, the transfer (sale) of shares from an existing member to:
        </p>
        <ul className="text-black text-xl mb-4 list-disc pl-8">
          <li>any other existing member, or</li>
          <li>a new member,</li>
        </ul>
        <p className="text-black text-xl mb-4">
          will be possible during the construction and operational periods of the OCREP project. Please submit an expression of interest in buying/selling shares in the Co-Operative by filling in the Contact form.
        </p>
      </section>

      {/* How does this work? */}
      <section className="mb-12 flex flex-col md:flex-row gap-8">
        <div className="md:w-1/2">
          <h2 className="text-3xl text-[#703aa4] mb-4">How does this work?</h2>
          <p className="text-black text-xl">
          The ED Central West NSW Renewable Energy Co-operative successfully reached the community fundraising goal of $1.35 Million to secure partial ownership of the Orange Community Renewable Energy Park (OCREP). This achievement ensures maximum possible community ownership before the park is commissioned in July 2025. The project also benefited from a $3.5M grant from the New South Wales Government's Regional Community Energy Fund.
          </p>
          <p className="text-black text-xl mt-4">
          The electricity generated by the park is fed into the National Grid, with excess power used to recharge a battery for later use or sold when electricity prices peak. By supporting this initiative, you have contributed to reducing carbon emissions for the entire lifespan of the park.
          </p>
        </div>
        <div className="md:w-1/2 flex items-center">
          <div className="w-full h-full">
            <img 
              src="/media/AdobeStock_158281524.webp" 
              alt="Renewable Energy Park" 
              className="w-full h-full object-cover rounded-lg shadow-md"
            />
          </div>
        </div>
      </section>

      {/* Community Benefits Section */}
      <section className="mb-12 text-center">
        <h2 className="text-3xl text-[#703aa4] mb-8">Benefits for Our Community</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Economic Growth</h3>
            <FaArrowTrendUp className="text-[#703aa4] text-4xl mb-2 mx-auto" />
            <p>Creating local jobs and stimulating the regional economy through renewable energy investments.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Energy Independence</h3>
            <TbSunElectricity className="text-[#703aa4] text-4xl mb-2 mx-auto" />
            <p>Reducing reliance on the grid and stabilizing energy costs for the community.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Environmental Impact</h3>
            <FaLeaf className="text-[#703aa4] text-4xl mb-2 mx-auto" />
            <p>Significantly reducing carbon emissions and promoting a cleaner, healthier environment.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Community Empowerment</h3>
            <MdEmojiPeople className="text-[#703aa4] text-4xl mb-2 mx-auto" />
            <p>Giving locals a stake in their energy future through cooperative ownership models.</p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-[#703aa4] text-white mt-8 mb-0 p-8 rounded-lg shadow-md text-center">
        <h2 className="text-3xl mb-4">Join the Energy Revolution in Central West NSW</h2>
        <p className="text-lg mb-6">
        Be a part of the sustainable future of our region. While initial investments are now closed, you can subscribe to our newsletter to stay informed about the project's progress and be the first to know about future investment opportunities.
        </p>
        <div>
          <a 
            href="https://energydemocracy.us14.list-manage.com/subscribe?u=531274272281a368367bd1700&id=ab566fa072" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-block"
          >
            <button className="bg-[#ff8400] hover:bg-[#e67600] text-white text-xl font-semibold shadow-md py-2 px-6 rounded-full transition duration-300">
              Subscribe to our newsletter
            </button>
          </a>
        </div>
      </section>

      {/* Divider */}
      <div className="flex justify-center mt-16">
        <img
          src="/media/logo-icon.svg" 
          alt="Icon"
          className="w-10 h-10"
        />
      </div>

      {/* Disclaimer Section */}
      <div className="mb-8 mt-8 pt-8 border-t border-gray-200">
        <hr className="border-t border-gray-600 w-full mb-4" />
          <p className="text-sm text-gray-600">
          * Please read the <a href="/media/Energy%20Democracy%20Disclosure%20Statement-2e79b172bc661b833ded6066d87fd44f.pdf" target="_blank" className="text-[#703aa4] underline">Disclosure Statement</a>. No application for relief under section 741 of the Corporations Act 2001 (Cth) will be sought by the Energy Democracy Central West NSW Co-operative Limited ('the Co-operative') in relation to any statement made in this document as the Chapter 6D disclosure provisions do not apply to members' shares in the Co-operative.
          </p>
      </div>
    </div>
  );
};

export default CentralWest;
